import { Renderer as LayoutRenderer } from "@obvio/layout";
import { FadeIn } from "@obvio/template";
import { Section } from "@obvio/ui";

import { customRenderers } from "./Renderer/renderers";

import type { RendererProps } from "@obvio/layout/renderer/utils";
import type { ReactElement } from "react";

type RendererCustomProps = RendererProps & {
  pageId: string;
};

export function Renderer({ data, pageId }: RendererCustomProps): ReactElement {
  return (
    <LayoutRenderer
      data={data}
      customRenderers={customRenderers(pageId)}
      section={({ children }) => (
        <Section context="white">
          <FadeIn>{children}</FadeIn>
        </Section>
      )}
    />
  );
}
