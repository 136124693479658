import { AssetsProvider, getHomeUrl } from '@obvio/app'
import { getStaticPathsPages, getStaticPropsPages } from '@obvio/server'
import { values } from '@obvio/utils'
import { NextSeo } from 'next-seo'

import { schema } from '@/api/schema'
import * as FEATURES from '@/api/schema/features'
import { Layout } from '@/components/Layout'
import { Renderer } from '@/components/Renderer'
import { RESOURCE_PREFIXES } from '@/constants'
import { QUERIES } from '@/constants/queries'

import type { RendererData } from '@obvio/layout/renderer'
import type { GetStaticPropsContext } from 'next'
import type { ReactElement } from 'react'

function Page({
  page,
}: {
  page: {
    id: string
    slug: string
    data: RendererData<any, any>[]
    layoutRef: { title: string }
    seoTitle?: string
    seoDescription?: string
  }
}): ReactElement {
  const canonical = `${getHomeUrl()}/${page.slug ?? ''}`

  return (
    <>
      <NextSeo
        canonical={canonical}
        {...(page.seoTitle && { title: page.seoTitle })}
        {...(page.seoDescription && { description: page.seoDescription })}
      />
      <AssetsProvider path={`/page/${page.id}`}>
        <Layout>
          <Renderer data={page.data} pageId={page.id} />
        </Layout>
      </AssetsProvider>
    </>
  )
}

export async function getStaticProps(
  ctx: GetStaticPropsContext<{ slug: string[] }>,
): Promise<ReturnType<typeof getStaticPropsPages>> {
  return getStaticPropsPages(ctx, schema, {
    queries: QUERIES,
    paths: RESOURCE_PREFIXES,
    features: values(FEATURES).map(({ id }) => id),
  })
}

export async function getStaticPaths(): Promise<
  ReturnType<typeof getStaticPathsPages>
> {
  return getStaticPathsPages(schema)
}

export default Page
